<template>
  <div>
		<b-row>
			<b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="UsersIcon"
					color="success"
          :statistic="status_member.active"
          statistic-title="Member Active"
        />
      </b-col>
			<b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="UsersIcon"
					color="secondary"
          :statistic="status_member.inactive"
          statistic-title="Member Inactive"
        />
      </b-col>
			<b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="UsersIcon"
					color="warning"
          :statistic="status_member.suspended"
          statistic-title="Member Suspended"
        />
      </b-col>
			<b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="UsersIcon"
					color="danger"
          :statistic="status_member.banned"
          statistic-title="Member Banned"
        />
      </b-col>
		</b-row>
		<div>
			<b-dropdown
				v-ripple.400="'rgba(113, 102, 240, 0.15)'"
				class="mb-2 mr-1"
				variant="primary"
				text="Add Member"
				right
			>
				<b-dropdown-item @click="gotoAddMember()">Input Manual</b-dropdown-item>
				<b-dropdown-item @click="gotoImportMember()">Import </b-dropdown-item>
			</b-dropdown>
			<b-button variant="primary" class="mb-2" @click="gotoBulkUpdate()">
				Bulk Update
			</b-button>
		</div>

		<b-overlay
			:show="overlayUserMember"
			spinner-variant="primary"
			spinner-type="grow"
			spinner-small
			rounded="sm"
		>
			<b-row class="mb-1">
				<b-col>
					<b-button size="sm" variant="outline-secondary" class="mr-1" disabled v-for="(item, index) in filters">
						<feather-icon :icon="item.icon" size="16" />
						{{ item.name | dotConcat }}
					</b-button>
					<b-button
						v-b-modal.modal-filter
      			v-ripple.400="'rgba(186, 191, 199, 0.15)'"
						size="sm"
						variant="flat-secondary" 
						class="mr-1"
					>
						<feather-icon
							icon="PlusIcon"
							size="16"
						/>
						Add Filter
					</b-button>
					<b-button
						v-if="filters.length > 0"
      			v-ripple.400="'rgba(186, 191, 199, 0.15)'"
						size="sm"
						variant="flat-danger"
						@click="resetFilter()"
					>
						<feather-icon
							icon="XIcon"
							size="16"
						/>
						Reset Filter
					</b-button>
				</b-col>
			</b-row>
			<b-card-code
				title="Data Member"
				no-body
        :aria-hidden="overlayUserMember ? true : null"
			>
				<b-card-body>
					<div class="d-flex justify-content-between flex-wrap">
						<!-- sorting  -->
						<b-form-group
							label="Sort"
							label-size="sm"
							label-align-sm="left"
							label-cols-sm="3"
							label-for="sortBySelect"
							class="mr-1 mb-md-0"
						>
							<b-input-group size="sm">
								<b-form-select
									id="sortBySelect"
									v-model="sortBy"
									:options="sortOptions"
								>
									<template #first>
										<option value="">
											none
										</option>
									</template>
								</b-form-select>
								<b-form-select
									v-model="sortDesc"
									size="sm"
									:disabled="!sortBy"
								>
									<option :value="false">
										ASC
									</option>
									<option :value="true">
										DESC
									</option>
								</b-form-select>
							</b-input-group>
						</b-form-group>

						<!-- filter -->
						<b-form-group
							label="Filter"
							label-cols-sm="2"
							label-align-sm="left"
							label-size="sm"
							label-for="filterInput"
							class="mb-0"
						>
							<b-input-group size="sm">
								<b-form-input
									id="filterInput"
									v-model="filter"
									type="search"
									placeholder="Type to Search"
								/>
								<b-input-group-append>
									<b-button
										:disabled="!filter"
										@click="filter = ''"
									>
										Clear
									</b-button>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</div>
				</b-card-body>

				<b-table
					striped
					hover
					responsive
					class="position-relative"
					:per-page="perPage"
					:current-page="currentPage"
					:items="items"
					:fields="fields"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
					:sort-direction="sortDirection"
					:filter="filter"
					:filter-included-fields="filterOn"
					@filtered="onFiltered"
				>
					<template #cell(name)="data">
						<div class="d-flex flex-row">
							<b-avatar v-if="data.item.logo !== null"
								class="mr-50"
								:src="`https://api.the-netwerk.com/public/storage/member/profile/${data.item.logo}`"
							/> 
							<b-avatar v-else
								class="mr-50"
								variant="primary"
								:text="data.item.name | initial"
							/>
							<div class="d-flex flex-column">
								<p class="m-0" v-if="data.item.name.length > 25"
									v-b-tooltip.hover.bottom="data.item.name">
									{{ data.item.name | dotConcat }}
								</p>
								<p class="m-0" v-else>{{ data.item.name }}</p>
								<small class="text-muted">
									{{ data.item.email }}
								</small>
							</div>
						</div>
					</template>

					<template #cell(corporate)="data">
						<div class="d-flex flex-column">
							<div v-if="data.item.corporate">
								<p class="m-0" v-if="data.item.corporate.length > 25"
									v-b-tooltip.hover.bottom="data.item.corporate">
									{{ `${data.item.corporate.substring(0, 25)}...` }}
								</p>
								<p class="m-0" v-else>
									{{ data.item.corporate }}
								</p>
							</div>
							<small class="text-muted">
								Divisi: {{ data.item.divisi }}
							</small>
						</div>
					</template>
					
					<template #cell(status)="data">
						<b-badge variant="light-success" 
							v-if="data.item.status === 'active'">
							Active
						</b-badge>
						<b-badge variant="light-secondary" 
							v-else-if="data.item.status === 'inactive'">
							Inactive
						</b-badge>
						<b-badge variant="light-warning" 
							v-else-if="data.item.status === 'suspended'">
							Suspended
						</b-badge>
						<b-badge variant="light-danger" 
							v-else-if="data.item.status === 'banned'">
							Banned
						</b-badge>
					</template>

					<template #cell(actions)="data">
						<span>
							<b-dropdown
								variant="link"
								toggle-class="text-decoration-none"
								no-caret
							>
								<template v-slot:button-content>
									<feather-icon
										icon="SettingsIcon"
										size="16"
										class="text-body align-middle mr-25"
									/>
								</template>
								<b-dropdown-item @click="gotoDetail(data.item.id)">
									<feather-icon
										icon="ListIcon"
										class="mr-50"
										style="margin-top: -5px"
									/>
									<span>Detail</span>
								</b-dropdown-item>
								<b-dropdown-item v-if="active_role == 'superadmin'" @click="resetPassword(data.item.email)">
									<feather-icon
										icon="KeyIcon"
										class="mr-50"
										style="margin-top: -5px"
									/>
									<span>Reset Password</span>
								</b-dropdown-item>
								<b-dropdown-item 
									v-if="active_role == 'superadmin' && data.item.status == 'inactive'" 
									@click="activateUser(data.item.user_id)">
									<feather-icon
										icon="UserCheckIcon"
										class="mr-50"
										style="margin-top: -5px"
									/>
									<span>Activate</span>
								</b-dropdown-item>
								<b-dropdown-item @click="gotoEdit(data.item.id)">
									<feather-icon
										icon="Edit2Icon"
										class="mr-50"
										style="margin-top: -5px"
									/>
									<span>Edit</span>
								</b-dropdown-item>
								<b-dropdown-item @click="gotoDelete(data.item.id)">
									<feather-icon
										icon="TrashIcon"
										class="mr-50"
										style="margin-top: -5px"
									/>
									<span>Delete</span>
								</b-dropdown-item>
							</b-dropdown>
						</span>
					</template>
				</b-table>

				<b-card-body class="d-flex justify-content-between flex-wrap pt-0">
					<!-- page length -->
					<b-form-group
						label="Per Page"
						label-cols="6"
						label-align="left"
						label-size="sm"
						label-for="sortBySelect"
						class="text-nowrap mb-md-0 mr-1"
					>
						<b-form-select
							id="perPageSelect"
							v-model="perPage"
							size="sm"
							inline
							:options="pageOptions"
						/>
					</b-form-group>

					<!-- pagination -->
					<div>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalRows"
							:per-page="perPage"
							first-number
							last-number
							prev-class="prev-item"
							next-class="next-item"
							class="mb-0"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</div>
				</b-card-body>
			</b-card-code>
    </b-overlay>
 
		<!-- Modal Add Filter -->
		<b-modal
      id="modal-filter"
      cancel-variant="outline-secondary"
      ok-title="Add"
      cancel-title="Close"
      centered
			no-close-on-backdrop
      title="Add Filter"
			@ok="setFilters"
    >
      <b-form>
        <b-form-group>
          <label for="email">Select properties:</label>
          <v-select
						v-model="filter_selected.property"
          	:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:options="filterOptions"
					/>
        </b-form-group>
        <b-form-group>
          <label for="password">Value</label>
          <v-select
						v-model="filter_selected.value"
          	:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:options="value_choose_selected"
					/>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
	import {
		BRow, BCol,
		BTable, BAvatar, BBadge, BCardBody, VBModal,
		BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, 
		BDropdown, BDropdownItem, BOverlay, VBTooltip, BForm
	} from 'bootstrap-vue'
	import vSelect from 'vue-select'
	import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
  import Ripple from 'vue-ripple-directive'
  
  export default {
    components: {
			BCardCode,
			BRow, BCol,
			BTable, BAvatar, BBadge, BCardBody, VBModal,
			BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, 
			BDropdown, BDropdownItem, BOverlay, BForm,
			StatisticCardHorizontal, vSelect
		},
    directives: {
    	'b-modal': VBModal,
			'b-tooltip': VBTooltip,
      Ripple,
    },
    data() {
      return {
        perPage: 50,
				pageOptions: [10, 50, 100],
				totalRows: 1,
				currentPage: 1,
				sortBy: '',
				sortDesc: false,
				sortDirection: 'asc',
				filter: null,
				filterOn: [],
				infoModal: {
					id: 'info-modal',
					title: '',
					content: '',
				},
				fields: [
					{ key: 'name', label: 'Name', sortable: true },
					{ key: 'phone', label: 'Phone' },
					{ key: 'corporate', label: 'Corporate', sortable: true },
					{ key: 'status', label: 'Status', sortable: true },
					{ key: 'actions', label: 'Actions' }
				],
				status_member: {
					active: 0,
					inactive: 0,
					suspended: 0,
					banned: 0
				},
				overlayUserMember: true,
        active_role: null,

				filters: [],
				filter_selected: {
					property: null,
					value: null
				},
				value_choose_selected: [],
				option_properties: [
					{ 
						icon: 'PackageIcon',
						label: 'Corporate', 
						value: []
					}, 
					{ 
						icon: 'ActivityIcon',
						label: 'Status',
						value: [
							{ code: 'active', label: 'Active' },
							{ code: 'inactive', label: 'Inactive' },
							{ code: 'suspended', label: 'Suspended' },
							{ code: 'banned', label: 'Banned' }
						]
					}
				],
      }
    },
		computed: {
			filterOptions() {
				let output = []
				const role = localStorage.getItem('role')

				if(role == 'corporate') {
					output.push(this.option_properties[1])
				} else {
					output = this.option_properties
				}

				return output
			},
			sortOptions() {
				// Create an options list from our fields
				return this.fields
					.filter(f => f.sortable)
					.map(f => ({ text: f.label, value: f.key }))
			},
			items() {
				let result = []
				let members_filter = []
				let filters = this.filters
        let members = this.$store.state.member.members
				
				if(filters.length > 0) {
					let corporate_filter = filters.find(item => {
						return item.type == 'Corporate'
					})

					let status_filter = filters.find(item => {
						return item.type == 'Status'
					})

					members_filter = members.filter(item => {
						if(corporate_filter !== undefined) {
							return item.corporate_id == corporate_filter.code
						} else {
							return true
						}
					})
					
					members_filter = members_filter.filter(item => {
						if(status_filter !== undefined) {
							return item.member_status == status_filter.code
						} else {
							return true
						}
					})

					result = members_filter
				} else {
					result = members
				}

				// Set total status member
				this.setTotalStatusMember(members)

				return result
      },
			corporate() {
				return this.$store.state.corporate.corporate
			}
		},
    watch: {
      items: function(newVal, oldVal) {
        if(newVal) {
					this.setDataMember()
        }
      },
			'filter_selected.property': function(newVal, oldVal) {
				if(newVal) {
					if(newVal.label == 'Corporate') {
						let corporate = this.corporate.map(item => {
							return {
								code: item.id,
								label: item.name
							}
						})
						
						this.value_choose_selected = corporate
					} else if(newVal.label == 'Status') {
						this.value_choose_selected = this.option_properties[1].value
					}
				}
			}
    },
		created() {
      this.checkAuth()
    	this.getDataMember()
			this.getDataCorporate()
    },
		mounted() {
      this.active_role = localStorage.getItem('role')
			this.getDataMember()
		}, 
		filters: {
			initial: function (val) {
				var result = "";
				let tokens = val.split("")

				if(tokens.length == 1) {
					result = tokens[0].substring(0,1).toUpperCase()
				} else {
					for(let i=0; i<2; i++){
						result += tokens[i].substring(0,1).toUpperCase()
					}
				}

				return result
			},
			dotConcat: function (val) {
				let result = ""
				let length_text = val.split("")

				if(length_text >= 25) {
					result = `${val}...`
				} else {
					result = val
				}

				return result
			}
		},
    methods: {
			info(item, index, button) {
				this.infoModal.title = `Row index: ${index}`
				this.infoModal.content = JSON.stringify(item, null, 2)
				this.$root.$emit('bv::show::modal', this.infoModal.id, button)
			},
			resetInfoModal() {
				this.infoModal.title = ''
				this.infoModal.content = ''
			},
			onFiltered(filteredItems) {
				// Trigger pagination to update the number of buttons/pages due to filtering
				this.totalRows = filteredItems.length
				this.currentPage = 1
			},

			getDataCorporate() {
				this.$store.dispatch('corporate/loadCorporate')
					.then(res => {})
					.catch(error => {})
			},
			getDataMember() {
				this.$store.dispatch('member/loadMember')
					.then(res => {})
					.catch(error => {})
			},
			setDataMember() {
				// Set the initial number of items
				this.totalRows = this.items.length

				this.overlayUserMember = false
			},
			setTotalStatusMember(data) {
				this.status_member.active = data.filter(item => {
					return item.status === 'active'
				}).length

				this.status_member.inactive = data.filter(item => {
					return item.status === 'inactive'
				}).length

				this.status_member.suspended = data.filter(item => {
					return item.status === 'suspended'
				}).length
				
				this.status_member.banned = data.filter(item => {
					return item.status === 'banned'
				}).length
			},
			setFilters() {
				let filter = this.filter_selected

				if(filter.property !== null || filter.value !== null) {
					let find_corporate_filter = this.filters.find(item => {
						return item.type == 'Corporate'
					})

					let find_status_filter = this.filters.find(item => {
						return item.type == 'Status'
					})

					if(find_corporate_filter) {
						this.filters = this.filters.filter(item => {
							return item.type !== 'Corporate'
						})
					}

					if(find_status_filter) {
						this.filters = this.filters.filter(item => {
							return item.type !== 'Status'
						})
					}

					let data = {
						code: filter.value.code,
						name: filter.value.label,
						icon: filter.property.icon,
						type: filter.property.label
					}

					this.filters.push(data)

					filter.property = null
					filter.value = null
					this.value_choose_selected = []
				}
			},
			resetFilter() {
				this.filters = []
			},

			gotoAddMember() {
				this.$router.push({ path: 'member/add' })
			},
			gotoImportMember() {
				this.$router.push({ path: 'member/import' })
			},
			gotoBulkUpdate() {
				this.$router.push({ path: 'member/bulk-update'})
			},
			gotoDetail(id) {
				this.$router.push({ path: `member/detail/${id}` })
			},
			gotoEdit(id) {
				this.$router.push({ path: `member/edit/${id}` })
			},
			gotoDelete(id) {
        let cid = localStorage.getItem('client_id')
				this.$bvModal
					.msgBoxConfirm('Are you sure to delete this data?', {
						title: 'Delete confirmation',
						size: 'sm',
						okVariant: 'danger',
						okTitle: 'Delete',
						cancelTitle: 'Cancel',
						cancelVariant: 'outline-secondary',
						hideHeaderClose: false,
						centered: true,
					})
					.then(value => {
						if(value) {
							this.$axios.delete(`${cid}/noauth/member/${id}`)
								.then(res => {
									// Success code
									this.$bvToast.toast('Success', {
										title: 'Delete data member',
										variant: 'success',
										solid: true,
										toaster: 'b-toaster-bottom-right',
									})
									
									this.items = []
									this.getDataMember()
								})
						}
				})
			},

			resetPassword(email) {
        let cid = localStorage.getItem('client_id')

				this.$bvModal
					.msgBoxConfirm('This user will recieve email for reset password, are you sure?', {
						title: 'Reset password',
						size: 'sm',
						okVariant: 'primary',
						okTitle: 'Submit',
						cancelTitle: 'Cancel',
						cancelVariant: 'outline-secondary',
						hideHeaderClose: false,
						centered: true,
					})
					.then(value => {
						if(value) {
							let formData = {
								email: email
							}
							
							this.$axios.post(`${cid}/reset-password`, formData)
								.then(res => {
									// Success code
									this.$bvToast.toast('Success', {
										title: 'Reset password',
										variant: 'success',
										solid: true,
										toaster: 'b-toaster-bottom-right',
									})
								})
						}
				})
			},
			activateUser(id) {
				let cid = localStorage.getItem('client_id')

				this.$bvModal
					.msgBoxConfirm('Are you sure to activate this user', {
						title: 'Activate User',
						size: 'sm',
						okVariant: 'primary',
						okTitle: 'Submit',
						cancelTitle: 'Cancel',
						cancelVariant: 'outline-secondary',
						hideHeaderClose: false,
						centered: true,
					})
					.then(value => {
						if(value) {
							const config = {
								headers: {
									'Authorization': 'Bearer ' + localStorage.getItem('token')
								}
							}

							this.$axios.get(`${cid}/member/activate/${id}`, config)
								.then(res => {
									// Success code
									this.$bvToast.toast('Success', {
										title: 'Activation user',
										variant: 'success',
										solid: true,
										toaster: 'b-toaster-bottom-right',
									})
								})
						}
				})
			}
    }
  }
</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-select.scss';
</style>